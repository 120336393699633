import { Box, Center, Flex, Space, Text, Title } from "@mantine/core"
import { signIn } from "next-auth/react"

import { GoogleButton } from "../../components/GoogleButton"
import { LINKS } from "../../constants/links"

export function Login() {
  return (
    <>
      <Center h="100vh">
        <Box>
          <Title size={26} w="bold" ta="center">
            Proteus Admin
          </Title>

          <Space h={30} />

          <Flex justify="center">
            <GoogleButton onClick={() => signIn("google", { callbackUrl: LINKS.home })}>
              <Text c="gray.7" size="sm" fw={500}>
                Continue with Google
              </Text>
            </GoogleButton>
          </Flex>
        </Box>
      </Center>
    </>
  )
}
